export const isTokenExpired = () => {
  const expirationTime = localStorage.getItem('tokenExpiration');
  if (!expirationTime) return true;
  
  return Date.now() >= parseInt(expirationTime);
};

export const getValidToken = () => {
  const token = localStorage.getItem('token');
  if (!token || isTokenExpired()) {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    return null;
  }
  return token;
};

export const setToken = (token) => {
  localStorage.setItem('token', token);
  // Set expiration time to 8 hours from now
  localStorage.setItem('tokenExpiration', Date.now() + (8 * 60 * 60 * 1000));
}; 