import React from "react";
import companyLogo from "../assets/logo-black.png";

function WejitLogin() {
  return (
    <div className="min-h-screen bg-neutral-200 flex flex-col">
      <div className="w-full h-12 bg-black px-36 flex items-center">
        <img alt="logo" src={companyLogo} className="h-10" />
      </div>
      <div className="h-full w-full flex justify-center items-center flex-1">
        <div className="w-24 min-h-[122px] -mt-8 bg-white p-3 border border-stone-400 shadow-md flex flex-col justify-center items-center">
          <p className="font-sans text-xl font-medium my-4">Login</p>
          <a
            href="/login"
            className="px-4 py-1.5 shadow-3xl rounded font-sans text-sm font-semibold uppercase tracking-normal"
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
}

export default WejitLogin;
