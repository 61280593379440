import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getValidToken } from '../utils/auth';

const QueryExecutor = () => {
  const [sqlQuery, setSqlQuery] = useState('');
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!sqlQuery.trim()) {
      setMessage('Proszę wprowadzić zapytanie SQL');
      return;
    }

    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    setIsLoading(true);
    setMessage('');

    try {
      const response = await fetch('/api/admin/query', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'text/plain',
        },
        body: sqlQuery.trim(),
      });

      if (response.ok) {
        const data = await response.json();
        setResults(data);
        setMessage('Zapytanie zostało wykonane pomyślnie');
      } else if (response.status === 401) {
        // Token expired or invalid
        navigate('/manager/login');
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas wykonywania zapytania'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas wykonywania zapytania');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="p-6 bg-white rounded-lg shadow-md w-full max-w-6xl">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Executor zapytań SQL</h1>
          <button
            onClick={() => navigate('/campaigns')}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Powrót
          </button>
        </div>

        {/* Formularz zapytania */}
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="mb-4">
            <label htmlFor="sqlQuery" className="block text-sm font-medium text-gray-700 mb-2">
              Zapytanie SQL
            </label>
            <textarea
              id="sqlQuery"
              value={sqlQuery}
              onChange={(e) => setSqlQuery(e.target.value)}
              className="w-full h-32 p-2 border rounded font-mono text-sm"
              placeholder="Wprowadź zapytanie SQL..."
              required
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:bg-gray-400"
          >
            {isLoading ? 'Wykonywanie...' : 'Wykonaj zapytanie'}
          </button>
        </form>

        {/* Wyniki */}
        {results && (
          <div className="mt-6">
            <h2 className="text-lg font-semibold mb-4">Wyniki zapytania</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {Object.keys(results[0] || {}).map((header) => (
                      <th
                        key={header}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {results.map((row, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      {Object.values(row).map((value, cellIndex) => (
                        <td
                          key={cellIndex}
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                        >
                          {value === null ? 'NULL' : String(value)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Komunikaty */}
        {message && (
          <p className={`mt-4 text-center ${
            message.includes('pomyślnie') ? 'text-green-600' : 'text-red-600'
          }`}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default QueryExecutor; 