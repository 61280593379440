import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getValidToken } from '../utils/auth';
import Sidebar from '../components/Sidebar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EmailTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    content: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
    } else {
      fetchTemplates();
    }
  }, [navigate]);

  const fetchTemplates = async () => {
    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch('/api/email-template', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Decode base64 content for each template
        const decodedTemplates = data.map(template => ({
          ...template,
          // Use TextDecoder for proper UTF-8 decoding after Base64
          content: new TextDecoder().decode(Uint8Array.from(atob(template.content), c => c.charCodeAt(0)))
        }));
        setTemplates(decodedTemplates);
      } else if (response.status === 401) {
        navigate('/manager/login');
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Nie udało się pobrać szablonów email'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas pobierania szablonów email');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const resetForm = () => {
    setFormData({
      name: '',
      subject: '',
      content: ''
    });
  };

  const openCreateModal = () => {
    resetForm();
    setShowCreateModal(true);
  };

  const openEditModal = (template) => {
    setSelectedTemplate(template);
    setFormData({
      name: template.name,
      subject: template.subject,
      content: template.content
    });
    setShowEditModal(true);
  };

  const openDeleteModal = (template) => {
    setSelectedTemplate(template);
    setShowDeleteModal(true);
  };

  const openPreviewModal = (template) => {
    const processedContent = template.content.replace(/placeholder\.fullName/g, 'Marcin');
    setPreviewData({ ...template, content: processedContent });
    setShowPreviewModal(true);
  };

  const validateForm = () => {
    if (!formData.name.trim()) {
      setMessage('Nazwa szablonu jest wymagana');
      return false;
    }
    if (!formData.subject.trim()) {
      setMessage('Temat wiadomości jest wymagany');
      return false;
    }
    if (!formData.content.trim()) {
      setMessage('Treść wiadomości jest wymagana');
      return false;
    }
    return true;
  };

  const handleCreateTemplate = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    setIsLoading(true);
    try {
      // Encode HTML content as base64 using TextEncoder for proper UTF-8 handling
      const uint8Array = new TextEncoder().encode(formData.content);
      const encodedContent = btoa(String.fromCharCode(...uint8Array));
      const requestData = {
        ...formData,
        content: encodedContent
      };

      const response = await fetch('/api/email-template', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setMessage('Szablon został pomyślnie utworzony');
        setShowCreateModal(false);
        resetForm();
        fetchTemplates();
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas tworzenia szablonu'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas tworzenia szablonu');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateTemplate = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    setIsLoading(true);
    try {
      // Encode HTML content as base64 using TextEncoder for proper UTF-8 handling
      const uint8Array = new TextEncoder().encode(formData.content);
      const encodedContent = btoa(String.fromCharCode(...uint8Array));
      const requestData = {
        ...formData,
        content: encodedContent
      };

      const response = await fetch(`/api/email-template/${selectedTemplate.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setMessage('Szablon został pomyślnie zaktualizowany');
        setShowEditModal(false);
        resetForm();
        fetchTemplates();
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas aktualizacji szablonu'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas aktualizacji szablonu');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTemplate = async () => {
    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`/api/email-template/${selectedTemplate.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok || response.status === 204) {
        setMessage('Szablon został pomyślnie usunięty');
        setShowDeleteModal(false);
        fetchTemplates();
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas usuwania szablonu'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas usuwania szablonu');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],
      ['link'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'color', 'background',
    'link'
  ];

  return (
    <Sidebar>
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-xl shadow-lg p-8">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Zarządzanie szablonami email</h1>
            <button
              onClick={openCreateModal}
              className="mt-4 sm:mt-0 inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
            >
              Utwórz nowy szablon
            </button>
          </div>

          {/* Lista szablonów */}
          <div className="space-y-6">
            {templates.length === 0 && !isLoading ? (
              <div className="text-center py-10 text-gray-500">
                Brak szablonów. Utwórz swój pierwszy szablon email.
              </div>
            ) : (
              templates.map((template) => (
                <div key={template.id} className="bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow p-6">
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="mb-4 md:mb-0">
                      <h2 className="text-xl font-semibold text-gray-900">{template.name}</h2>
                      <p className="text-gray-600 mt-1">Temat: {template.subject}</p>
                    </div>
                    <div className="flex space-x-3">
                      <button
                        onClick={() => openEditModal(template)}
                        className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
                      >
                        Edytuj
                      </button>
                      <button
                        onClick={() => openPreviewModal(template)}
                        className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
                      >
                        Podgląd
                      </button>
                      <button
                        onClick={() => openDeleteModal(template)}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors"
                      >
                        Usuń
                      </button>
                    </div>
                  </div>
                  <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                    <div className="text-sm text-gray-800 max-h-32 overflow-y-auto">
                      <div dangerouslySetInnerHTML={{ __html: template.content }} />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          {/* Komunikaty */}
          {message && (
            <div className={`mt-6 p-4 rounded-lg ${
              message.includes('pomyślnie') 
                ? 'bg-green-50 border-l-4 border-green-400 text-green-700' 
                : 'bg-red-50 border-l-4 border-red-400 text-red-700'
            }`}>
              {message}
            </div>
          )}

          {/* Create Modal */}
          {showCreateModal && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={() => setShowCreateModal(false)} />
              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="bg-white rounded-lg p-8 max-w-2xl w-full mx-auto shadow-xl">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6">Utwórz nowy szablon email</h2>
                  <form onSubmit={handleCreateTemplate} className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Nazwa szablonu
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Temat wiadomości
                      </label>
                      <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Treść wiadomości (HTML)
                      </label>
                      <div className="h-64">
                        <ReactQuill
                          theme="snow"
                          value={formData.content}
                          onChange={(content) => setFormData(prev => ({ ...prev, content }))}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </div>
                    <div className="flex justify-end space-x-4 pt-4">
                      <button
                        type="button"
                        onClick={() => setShowCreateModal(false)}
                        className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                      >
                        Anuluj
                      </button>
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors disabled:bg-indigo-400"
                      >
                        {isLoading ? 'Tworzenie...' : 'Utwórz szablon'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}

          {/* Edit Modal */}
          {showEditModal && selectedTemplate && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={() => setShowEditModal(false)} />
              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="bg-white rounded-lg p-8 max-w-2xl w-full mx-auto shadow-xl">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6">Edytuj szablon email</h2>
                  <form onSubmit={handleUpdateTemplate} className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Nazwa szablonu
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Temat wiadomości
                      </label>
                      <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Treść wiadomości (HTML)
                      </label>
                      <div className="h-64">
                        <ReactQuill
                          theme="snow"
                          value={formData.content}
                          onChange={(content) => setFormData(prev => ({ ...prev, content }))}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </div>
                    <div className="flex justify-end space-x-4 pt-4">
                      <button
                        type="button"
                        onClick={() => setShowEditModal(false)}
                        className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                      >
                        Anuluj
                      </button>
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors disabled:bg-indigo-400"
                      >
                        {isLoading ? 'Aktualizowanie...' : 'Zapisz zmiany'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}

          {/* Delete Confirmation Modal */}
          {showDeleteModal && selectedTemplate && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={() => setShowDeleteModal(false)} />
              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="bg-white rounded-lg p-8 max-w-md mx-auto shadow-xl">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">Potwierdź usunięcie</h2>
                  <p className="text-gray-600 mb-6">
                    Czy na pewno chcesz usunąć szablon "{selectedTemplate.name}"? Ta operacja jest nieodwracalna.
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      onClick={() => setShowDeleteModal(false)}
                      className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                    >
                      Anuluj
                    </button>
                    <button
                      onClick={handleDeleteTemplate}
                      disabled={isLoading}
                      className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors disabled:bg-red-400"
                    >
                      {isLoading ? 'Usuwanie...' : 'Usuń'}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Preview Modal */}
          {showPreviewModal && previewData && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={() => setShowPreviewModal(false)} />
              <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-lg p-8 max-w-3xl w-full mx-auto shadow-xl max-h-[90vh] overflow-y-auto">
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-900">Podgląd emaila</h2>
                    <button
                      onClick={() => setShowPreviewModal(false)}
                      className="text-gray-400 hover:text-gray-600"
                    >
                      <span className="sr-only">Zamknij</span>
                      X
                    </button>
                  </div>
                  <div className="border border-gray-200 rounded-lg p-4">
                    <div className="flex justify-between items-center border-b border-gray-200 pb-2 mb-4">
                        <div>
                            <p className="font-semibold text-gray-800">{previewData.subject}</p>
                            <p className="text-sm text-gray-500">Od: <span className="font-medium">System</span> &lt;noreply@example.com&gt;</p>
                            <p className="text-sm text-gray-500">Do: <span className="font-medium">Marcin</span> &lt;marcin@example.com&gt;</p>
                        </div>
                        <div className="text-sm text-gray-500">
                            {new Date().toLocaleString()}
                        </div>
                    </div>
                    <div className="prose max-w-none">
                      <div dangerouslySetInnerHTML={{ __html: previewData.content }} />
                    </div>
                  </div>
                  <div className="flex justify-end mt-6">
                    <button
                      type="button"
                      onClick={() => setShowPreviewModal(false)}
                      className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                    >
                      Zamknij podgląd
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default EmailTemplates; 