import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import WejitLogin from "./WejitLogin";
import TimeportsLogin from "./TimeportsLogin";

function LandingPage() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [scenario, setScenario] = useState(null);

  useEffect(() => {
    const fetchScenario = async () => {
      try {
        const params = new URLSearchParams(searchParams);
        const response = await fetch(`/api/user/me?${params}`, {
          method: "GET",
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        setScenario(data.scenario);
      } catch (error) {
        console.error("Error fetching scenario:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchScenario();
  }, [searchParams]);

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <p className="text-xl">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
        <h1 className="text-4xl font-bold mb-4">404</h1>
        <p className="text-xl mb-8">Page not found</p>
        <p className="text-gray-600">The page you are looking for doesn't exist or has been moved.</p>
      </div>
    );
  }

  if (scenario === "wejit") {
    return <WejitLogin />;
  } else if (scenario === "timeports") {
    return <TimeportsLogin />;
  } else {
    // Default to WejitLogin if scenario is not recognized
    return <WejitLogin />;
  }
}

export default LandingPage; 