import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getValidToken } from '../utils/auth';
import Sidebar from '../components/Sidebar';

const CampaignManager = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [campaignStart, setCampaignStart] = useState('');
  const [campaignEnd, setCampaignEnd] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [errors, setErrors] = useState({
    campaignName: '',
    campaignStart: '',
    campaignEnd: '',
    file: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
    } else {
      fetchCampaigns();
    }
  }, [navigate]);

  const fetchCampaigns = async () => {
    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    try {
      const response = await fetch('/api/campaign', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCampaigns(data);
      } else if (response.status === 401) {
        // Token expired or invalid
        navigate('/manager/login');
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Nie udało się pobrać kampanii'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas pobierania kampanii');
      console.error('Błąd:', error);
    }
  };

  const clearError = (field) => {
    setErrors(prev => ({...prev, [field]: ''}));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.name.endsWith('.csv')) {
      setFile(selectedFile);
      setMessage('');
      clearError('file');
    } else {
      setFile(null);
      setMessage('Proszę wybrać plik CSV');
      setErrors(prev => ({...prev, file: 'Wybierz prawidłowy plik CSV'}));
    }
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    
    setErrors({
      campaignName: '',
      campaignStart: '',
      campaignEnd: '',
      file: ''
    });
    setMessage('');

    let hasErrors = false;

    if (file && !file.name.endsWith('.csv')) {
      setErrors(prev => ({...prev, file: 'Plik musi być w formacie CSV'}));
      hasErrors = true;
    }

    if (!campaignName.trim()) {
      setErrors(prev => ({...prev, campaignName: 'Proszę podać nazwę kampanii'}));
      hasErrors = true;
    } else if (campaignName.trim().length < 3) {
      setErrors(prev => ({...prev, campaignName: 'Nazwa kampanii musi mieć co najmniej 3 znaki'}));
      hasErrors = true;
    }

    if (!campaignStart) {
      setErrors(prev => ({...prev, campaignStart: 'Proszę podać datę rozpoczęcia'}));
      hasErrors = true;
    }

    if (!campaignEnd) {
      setErrors(prev => ({...prev, campaignEnd: 'Proszę podać datę zakończenia'}));
      hasErrors = true;
    }

    if (campaignStart && campaignEnd) {
      const startDate = new Date(campaignStart);
      const endDate = new Date(campaignEnd);

      if (startDate >= endDate) {
        setErrors(prev => ({...prev, campaignEnd: 'Data zakończenia musi być późniejsza niż data rozpoczęcia'}));
        hasErrors = true;
      }
    }

    if (hasErrors) {
      return;
    }

    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    const startDate = new Date(campaignStart);
    const endDate = new Date(campaignEnd);
    const formattedStartDate = startDate.toISOString();
    const formattedEndDate = endDate.toISOString();

    setIsLoading(true);
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }

    console.log('Sending request with:', {
      campaignName: campaignName.trim(),
      campaignStart: formattedStartDate,
      campaignEnd: formattedEndDate,
      fileType: file ? file.type : 'none',
      fileName: file ? file.name : 'none',
      fileSize: file ? file.size : 0
    });

    try {
      const requestUrl = `/api/campaign?campaignName=${encodeURIComponent(campaignName.trim())}&campaignStart=${encodeURIComponent(formattedStartDate)}&campaignEnd=${encodeURIComponent(formattedEndDate)}`;
      console.log('Request URL:', requestUrl);

      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      console.log('Response status:', response.status);
      
      if (response.ok) {
        setMessage('Kampania została pomyślnie utworzona');
        setFile(null);
        setCampaignName('');
        setCampaignStart('');
        setCampaignEnd('');
        fetchCampaigns();
      } else {
        const errorData = await response.json();
        console.error('API error response:', errorData);
        
        if (errorData.fieldErrors) {
          const newErrors = {...errors};
          
          errorData.fieldErrors.forEach(fieldError => {
            const fieldName = fieldError.field === 'name' ? 'campaignName' : fieldError.field;
            newErrors[fieldName] = fieldError.message;
          });
          
          setErrors(newErrors);
        }
        
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas tworzenia kampanii'}`);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setMessage('Wystąpił błąd podczas tworzenia kampanii');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCampaignDetails = (campaignId) => {
    navigate(`/campaigns/${campaignId}`);
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '-';
    const date = new Date(timestamp);
    return date.toLocaleString('pl-PL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <Sidebar>
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-xl shadow-lg p-8">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4 mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Panel zarządzania kampaniami</h1>
          </div>

          {/* Lista kampanii */}
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Dostępne kampanie</h2>
            <div className="space-y-4">
              {campaigns.map((campaign) => (
                <div key={campaign.id} className="bg-white rounded-lg border border-gray-200 shadow-md hover:shadow-lg transition-shadow">
                  <div className="p-6">
                    <div className="flex flex-col space-y-4">
                      <div className="flex justify-between items-start">
                        <div className="space-y-1">
                          <div className="flex flex-col space-y-2">
                            <h2 className="text-2xl font-bold text-gray-900">{campaign.name}</h2>
                            <div className="flex items-center">
                              <span className="font-medium mr-2">Status:</span>
                              <span className={`px-3 py-1 text-sm rounded-full font-medium self-start ${
                                campaign.status === 'FINISHED' 
                                  ? 'bg-emerald-100 text-emerald-800' 
                                  : campaign.status === 'SCHEDULED'
                                  ? 'bg-sky-100 text-sky-800'
                                  : campaign.status === 'SENDING_EMAILS'
                                  ? 'bg-violet-100 text-violet-800'
                                  : campaign.status === 'ERROR_SENDING_EMAILS'
                                  ? 'bg-red-100 text-red-800'
                                  : campaign.status === 'IN_PROGRESS'
                                  ? 'bg-amber-100 text-amber-800'
                                  : 'bg-gray-100 text-gray-800'
                              }`}>
                                {campaign.status === 'FINISHED' ? 'Zakończona' :
                                 campaign.status === 'SCHEDULED' ? 'Zaplanowana' :
                                 campaign.status === 'SENDING_EMAILS' ? 'Wysyłanie maili' :
                                 campaign.status === 'ERROR_SENDING_EMAILS' ? 'Błąd wysyłania' :
                                 campaign.status === 'IN_PROGRESS' ? 'W trakcie' :
                                 campaign.status === 'NEW' ? 'Nowa' : campaign.status}
                              </span>
                            </div>
                          </div>
                          <div className="text-sm text-gray-600 space-y-1">
                            {campaign.executionTimestamp && (
                              <p>Data wykonania: {formatDate(campaign.executionTimestamp)}</p>
                            )}
                            <p>Rozpoczęcie: {formatDate(campaign.campaignStart)}</p>
                            <p>Zakończenie: {formatDate(campaign.campaignEnd)}</p>
                          </div>
                        </div>
                        <button
                          onClick={() => handleCampaignDetails(campaign.id)}
                          className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
                        >
                          Szczegóły
                        </button>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="bg-gray-50 rounded-lg p-4 flex flex-col items-center justify-center">
                          <span className="text-sm font-medium text-gray-600 mb-2">Cele</span>
                          <div className="relative w-16 h-16 flex items-center justify-center">
                            {/* Transparent circle for targets (not visible to user) */}
                            <svg className="w-16 h-16 absolute" viewBox="0 0 100 100">
                              <circle 
                                cx="50" 
                                cy="50" 
                                r="45" 
                                fill="transparent" 
                                stroke="transparent"
                                strokeWidth="10"
                              />
                            </svg>
                            <div className="text-2xl font-bold text-gray-900 z-10">{campaign.targetsCount || 0}</div>
                          </div>
                        </div>
                        <div className="bg-gray-50 rounded-lg p-4 flex flex-col items-center justify-center">
                          <span className="text-sm font-medium text-gray-600 mb-2">Kliknięcia</span>
                          <div className="relative w-16 h-16 flex items-center justify-center">
                            {/* Circular progress bar for clicks */}
                            <svg className="w-16 h-16 -rotate-90 absolute" viewBox="0 0 100 100">
                              {/* Background circle */}
                              <circle 
                                cx="50" 
                                cy="50" 
                                r="45" 
                                fill="transparent" 
                                stroke="#e5e7eb"
                                strokeWidth="10"
                              />
                              {/* Progress circle */}
                              <circle 
                                cx="50" 
                                cy="50" 
                                r="45" 
                                fill="transparent" 
                                stroke="#fcd34d" 
                                strokeWidth="10"
                                strokeDasharray="283"
                                strokeDashoffset={283 - (283 * (campaign.clicksPercentage || 0) / 100)}
                                strokeLinecap="round"
                              />
                            </svg>
                            <div className="text-2xl font-bold text-gray-900 z-10">{campaign.clicksCount || 0}</div>
                          </div>
                        </div>
                        <div className="bg-gray-50 rounded-lg p-4 flex flex-col items-center justify-center">
                          <span className="text-sm font-medium text-gray-600 mb-2">Logowania</span>
                          <div className="relative w-16 h-16 flex items-center justify-center">
                            {/* Circular progress bar for logins */}
                            <svg className="w-16 h-16 -rotate-90 absolute" viewBox="0 0 100 100">
                              {/* Background circle */}
                              <circle 
                                cx="50" 
                                cy="50" 
                                r="45" 
                                fill="transparent" 
                                stroke="#e5e7eb"
                                strokeWidth="10"
                              />
                              {/* Progress circle */}
                              <circle 
                                cx="50" 
                                cy="50" 
                                r="45" 
                                fill="transparent" 
                                stroke="#ef4444" 
                                strokeWidth="10"
                                strokeDasharray="283"
                                strokeDashoffset={283 - (283 * (campaign.loginsPercentage || 0) / 100)}
                                strokeLinecap="round"
                              />
                            </svg>
                            <div className="text-2xl font-bold text-gray-900 z-10">{campaign.loginsCount || 0}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Tworzenie nowej kampanii */}
          <div className="bg-gray-50 rounded-xl p-6 border border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Utwórz nową kampanię</h2>
            <form onSubmit={handleUploadSubmit} className="space-y-6">
              <div className="space-y-4">
                <div>
                  <label htmlFor="campaignName" className="block text-sm font-medium text-gray-700">
                    Nazwa kampanii
                  </label>
                  <input
                    id="campaignName"
                    type="text"
                    value={campaignName}
                    onChange={(e) => {
                      setCampaignName(e.target.value);
                      clearError('campaignName');
                    }}
                    className={`mt-1 block w-full px-4 py-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors ${
                      errors.campaignName ? 'border-red-500 bg-red-50' : 'border-gray-300'
                    }`}
                    placeholder="Wprowadź nazwę kampanii"
                  />
                  {errors.campaignName && (
                    <p className="mt-1 text-sm text-red-600">{errors.campaignName}</p>
                  )}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="campaignStart" className="block text-sm font-medium text-gray-700">
                      Data rozpoczęcia
                    </label>
                    <input
                      id="campaignStart"
                      type="datetime-local"
                      value={campaignStart}
                      onChange={(e) => {
                        setCampaignStart(e.target.value);
                        clearError('campaignStart');
                      }}
                      className={`mt-1 block w-full px-4 py-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors ${
                        errors.campaignStart ? 'border-red-500 bg-red-50' : 'border-gray-300'
                      }`}
                      required
                    />
                    {errors.campaignStart && (
                      <p className="mt-1 text-sm text-red-600">{errors.campaignStart}</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="campaignEnd" className="block text-sm font-medium text-gray-700">
                      Data zakończenia
                    </label>
                    <input
                      id="campaignEnd"
                      type="datetime-local"
                      value={campaignEnd}
                      onChange={(e) => {
                        setCampaignEnd(e.target.value);
                        clearError('campaignEnd');
                      }}
                      className={`mt-1 block w-full px-4 py-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors ${
                        errors.campaignEnd ? 'border-red-500 bg-red-50' : 'border-gray-300'
                      }`}
                      required
                    />
                    {errors.campaignEnd && (
                      <p className="mt-1 text-sm text-red-600">{errors.campaignEnd}</p>
                    )}
                  </div>
                </div>
                <div>
                  <label htmlFor="file" className="block text-sm font-medium text-gray-700">
                    Plik CSV z celami (opcjonalnie)
                  </label>
                  <input
                    id="file"
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    className={`mt-1 block w-full px-4 py-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors ${
                      errors.file ? 'border-red-500 bg-red-50' : 'border-gray-300'
                    }`}
                  />
                  {errors.file && (
                    <p className="mt-1 text-sm text-red-600">{errors.file}</p>
                  )}
                  {!errors.file && (
                    <p className="mt-1 text-sm text-gray-500">Załączenie pliku jest opcjonalne przy tworzeniu kampanii.</p>
                  )}
                </div>
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors disabled:bg-indigo-400"
              >
                {isLoading ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : 'Utwórz kampanię'}
              </button>
            </form>
          </div>

          {/* Komunikaty */}
          {message && (
            <div className={`mt-6 p-4 rounded-lg ${
              message.includes('pomyślnie') 
                ? 'bg-green-50 border-l-4 border-green-400 text-green-700' 
                : 'bg-red-50 border-l-4 border-red-400 text-red-700'
            }`}>
              {message}
            </div>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default CampaignManager;