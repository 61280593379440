import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AzureLogin from "./pages/AzureLogin";
import DonePage from "./pages/DonePage";
import WejitLogin from "./pages/WejitLogin";
import TimeportsLogin from "./pages/TimeportsLogin";
import CampaignManager from "./pages/CampaignManager";
import CampaignDetail from "./pages/CampaignDetail";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminLogin from "./pages/AdminLogin";
import QueryExecutor from "./pages/QueryExecutor";
import ScenarioManager from "./pages/ScenarioManager";
import EmailTemplates from "./pages/EmailTemplates";
import { SidebarProvider } from "./context/SidebarContext";
import LandingPage from "./pages/LandingPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/wejit",
    element: <WejitLogin />,
  },
  {
    path: "/timeports",
    element: <TimeportsLogin />,
  },
  {
    path: "/login",
    element: <AzureLogin />,
  },
  {
    path: "/done",
    element: <DonePage />,
  },
  {
    path: "/manager/login",
    element: <AdminLogin />,
  },
  {
    path: "/campaigns",
    element: <ProtectedRoute><CampaignManager /></ProtectedRoute>,
  },
  {
    path: "/campaigns/:campaignId",
    element: <ProtectedRoute><CampaignDetail /></ProtectedRoute>,
  },
  {
    path: "/scenarios",
    element: <ProtectedRoute><ScenarioManager /></ProtectedRoute>,
  },
  {
    path: "/email-templates",
    element: <ProtectedRoute><EmailTemplates /></ProtectedRoute>,
  },
  {
    path: "/query",
    element: <ProtectedRoute><QueryExecutor /></ProtectedRoute>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SidebarProvider>
      <RouterProvider router={router} />
    </SidebarProvider>
  </React.StrictMode>
);
