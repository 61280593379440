import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';

const ScenarioManager = () => {
  const navigate = useNavigate();

  return (
    <Sidebar>
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-xl shadow-lg p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">Zarządzanie scenariuszami</h1>
          <div className="text-center text-gray-600">
            Ta funkcjonalność będzie dostępna wkrótce.
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default ScenarioManager; 