import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReportTable from "../components/ReportTable";
import { getValidToken } from '../utils/auth';
import Sidebar from '../components/Sidebar';

const CampaignDetail = () => {
  const { campaignId } = useParams();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [targetFile, setTargetFile] = useState(null);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [newTarget, setNewTarget] = useState('');
  const [newTargetName, setNewTargetName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editCampaignName, setEditCampaignName] = useState('');
  const [editCampaignStart, setEditCampaignStart] = useState('');
  const [editCampaignEnd, setEditCampaignEnd] = useState('');
  const navigate = useNavigate();

  const fetchCampaignDetails = useCallback(async () => {
    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    try {
      const response = await fetch(`/api/campaign/${campaignId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCampaign(data);
        setLastRefresh(new Date());
      } else if (response.status === 401) {
        navigate('/manager/login');
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Nie udało się pobrać szczegółów kampanii'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas pobierania szczegółów kampanii');
      console.error('Błąd:', error);
    }
  }, [campaignId, navigate]);

  // Auto-refresh effect with visibility check
  useEffect(() => {
    let intervalId = null;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Tab became hidden, clear interval if it exists
        if (intervalId) {
          clearInterval(intervalId);
          intervalId = null;
        }
      } else {
        // Tab became visible, fetch immediately and start interval
        fetchCampaignDetails(); 
        if (!intervalId) { // Prevent multiple intervals
          intervalId = setInterval(fetchCampaignDetails, 30000); // Increased to 30 seconds
        }
      }
    };

    // Initial fetch and interval setup only if not hidden
    if (!document.hidden) {
      fetchCampaignDetails(); 
      intervalId = setInterval(fetchCampaignDetails, 30000); // Increased to 30 seconds
    }

    // Listen for visibility changes
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [fetchCampaignDetails]);

  // Effect for initial load (remains largely unchanged, but removed redundant fetch call)
  useEffect(() => {
    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
    }
    // The other effect now handles the initial fetch if the tab is visible
  }, [navigate, campaignId]); // Removed fetchCampaignDetails dependency here

  const handleTargetFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.name.endsWith('.csv')) {
      setTargetFile(selectedFile);
      setMessage('');
    } else {
      setTargetFile(null);
      setMessage('Proszę wybrać plik CSV');
    }
  };

  const handleUploadTargets = async () => {
    if (!targetFile) {
      setMessage('Proszę najpierw wybrać plik z celami');
      return;
    }

    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', targetFile);

    try {
      const response = await fetch(`/api/campaign/${campaignId}/target/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        setMessage('Cele zostały pomyślnie dodane do kampanii');
        setTargetFile(null);
        fetchCampaignDetails();
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas dodawania celów'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas dodawania celów');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScheduleCampaign = async () => {
    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`/api/campaign/${campaignId}/schedule`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setMessage('Kampania została pomyślnie zaplanowana');
        fetchCampaignDetails();
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas planowania kampanii'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas planowania kampanii');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTarget = async (targetId) => {
    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`/api/campaign/${campaignId}/target/${targetId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setMessage('Cel został pomyślnie usunięty');
        fetchCampaignDetails();
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas usuwania celu'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas usuwania celu');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportReport = async () => {
    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    setIsLoading(true);
    setMessage('');

    try {
      const response = await fetch(`/api/campaign/report/export?campaignId=${campaignId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const filename = response.headers.get('content-disposition')
          ?.split('filename=')[1]
          ?.replace(/["]/g, '') || 'report.xlsx';

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(downloadUrl);
        setMessage('Raport został pomyślnie pobrany!');
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas pobierania raportu'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas pobierania raportu');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddTarget = async (e) => {
    e.preventDefault();
    if (!newTarget.trim()) {
      setMessage('Proszę podać adres email celu');
      return;
    }

    if (!newTargetName.trim()) {
      setMessage('Proszę podać imię celu');
      return;
    }

    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`/api/campaign/${campaignId}/target`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: newTarget.trim(),
            name: newTargetName.trim()
        }),
      });

      if (response.ok) {
        setMessage('Cel został pomyślnie dodany');
        setNewTarget('');
        setNewTargetName('');
        fetchCampaignDetails();
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas dodawania celu'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas dodawania celu');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '-';
    const date = new Date(timestamp);
    return date.toLocaleString('pl-PL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Transform campaign data for ReportTable
  const getReportData = () => {
    if (!campaign?.targets) return [];

    return campaign.targets.map(target => ({
      id: target.id,
      name: target.name,
      email: target.email,
      clicked: target.clicked,
      loggedIn: target.loggedIn,
      emailSent: target.emailSent,
      clickTimestamp: target.clickTimestamp,
      loginTimestamp: target.loginTimestamp
    }));
  };

  const handleDeleteCampaign = async () => {
    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`/api/campaign/${campaignId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        navigate('/campaigns');
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas usuwania kampanii'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas usuwania kampanii');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
      setShowDeleteModal(false);
    }
  };

  const openEditModal = () => {
    if (campaign) {
      // Format dates for datetime-local input
      const formatDateForInput = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date(timestamp);
        return date.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
      };
      
      setEditCampaignName(campaign.name || '');
      setEditCampaignStart(formatDateForInput(campaign.campaignStart));
      setEditCampaignEnd(formatDateForInput(campaign.campaignEnd));
      setShowEditModal(true);
    }
  };

  const handleEditCampaign = async (e) => {
    e.preventDefault();
    
    if (!editCampaignName.trim()) {
      setMessage('Nazwa kampanii jest wymagana');
      return;
    }

    const token = getValidToken();
    if (!token) {
      navigate('/manager/login');
      return;
    }

    // Format dates the same way as in CampaignManager
    const startDate = new Date(editCampaignStart);
    const endDate = new Date(editCampaignEnd);
    const formattedStartDate = startDate.toISOString();
    const formattedEndDate = endDate.toISOString();

    setIsLoading(true);
    try {
      const requestUrl = `/api/campaign/${campaignId}?campaignName=${encodeURIComponent(editCampaignName.trim())}&campaignStart=${encodeURIComponent(formattedStartDate)}&campaignEnd=${encodeURIComponent(formattedEndDate)}`;
      
      const response = await fetch(requestUrl, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setMessage('Kampania została pomyślnie zaktualizowana');
        setShowEditModal(false);
        
        // Ensure UI is updated with the latest data from the server
        // Add a small delay to ensure the server has processed the update
        setTimeout(() => {
          fetchCampaignDetails();
        }, 300);
      } else {
        const errorData = await response.json();
        setMessage(`Błąd: ${errorData.message || 'Wystąpił błąd podczas aktualizacji kampanii'}`);
      }
    } catch (error) {
      setMessage('Wystąpił błąd podczas aktualizacji kampanii');
      console.error('Błąd:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Sidebar>
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-xl shadow-lg p-8">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4 mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Szczegóły kampanii</h1>
            <div className="flex flex-wrap items-center gap-3">
              <button
                onClick={() => navigate('/campaigns')}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
              >
                ← Powrót
              </button>
              <button
                onClick={openEditModal}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
                disabled={campaign?.status === 'FINISHED'}
              >
                Edytuj kampanię
              </button>
              <button
                onClick={() => setShowDeleteModal(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors"
              >
                Usuń kampanię
              </button>
            </div>
          </div>

          {/* Edit Campaign Modal */}
          {showEditModal && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={() => setShowEditModal(false)} />
              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="bg-white rounded-lg p-8 max-w-md mx-auto shadow-xl">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">Edytuj kampanię</h2>
                  <form onSubmit={handleEditCampaign} className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Nazwa kampanii
                      </label>
                      <input
                        type="text"
                        value={editCampaignName}
                        onChange={(e) => setEditCampaignName(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Data rozpoczęcia
                      </label>
                      <input
                        type="datetime-local"
                        value={editCampaignStart}
                        onChange={(e) => setEditCampaignStart(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Data zakończenia
                      </label>
                      <input
                        type="datetime-local"
                        value={editCampaignEnd}
                        onChange={(e) => setEditCampaignEnd(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors"
                        required
                      />
                    </div>
                    <div className="flex justify-end space-x-4 pt-4">
                      <button
                        type="button"
                        onClick={() => setShowEditModal(false)}
                        className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                      >
                        Anuluj
                      </button>
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors disabled:bg-indigo-400"
                      >
                        {isLoading ? 'Zapisywanie...' : 'Zapisz zmiany'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}

          {/* Delete Confirmation Modal */}
          {showDeleteModal && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={() => setShowDeleteModal(false)} />
              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="bg-white rounded-lg p-8 max-w-md mx-auto shadow-xl">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">Potwierdź usunięcie</h2>
                  <p className="text-gray-600 mb-6">
                    Czy na pewno chcesz usunąć tę kampanię? Ta operacja jest nieodwracalna.
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      onClick={() => setShowDeleteModal(false)}
                      className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                    >
                      Anuluj
                    </button>
                    <button
                      onClick={handleDeleteCampaign}
                      disabled={isLoading}
                      className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors disabled:bg-red-400"
                    >
                      {isLoading ? 'Usuwanie...' : 'Usuń'}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {campaign && (
            <div className="mb-8">
              <div className="flex flex-col space-y-2 mb-4">
                <h2 className="text-2xl font-bold text-gray-900">{campaign.name}</h2>
                <div className="flex items-center">
                  <span className="font-medium mr-2">Status:</span>
                  <span className={`px-3 py-1 text-sm rounded-full font-medium self-start ${
                    campaign?.status === 'FINISHED' 
                      ? 'bg-emerald-100 text-emerald-800' 
                      : campaign?.status === 'SCHEDULED'
                      ? 'bg-sky-100 text-sky-800'
                      : campaign?.status === 'SENDING_EMAILS'
                      ? 'bg-violet-100 text-violet-800'
                      : campaign?.status === 'ERROR_SENDING_EMAILS'
                      ? 'bg-red-100 text-red-800'
                      : campaign?.status === 'IN_PROGRESS'
                      ? 'bg-amber-100 text-amber-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {campaign?.status === 'FINISHED' ? 'Zakończona' :
                     campaign?.status === 'SCHEDULED' ? 'Zaplanowana' :
                     campaign?.status === 'SENDING_EMAILS' ? 'Wysyłanie maili' :
                     campaign?.status === 'ERROR_SENDING_EMAILS' ? 'Błąd wysyłania' :
                     campaign?.status === 'IN_PROGRESS' ? 'W trakcie' :
                     campaign?.status === 'NEW' ? 'Nowa' : campaign?.status}
                  </span>
                </div>
              </div>
              <div className="space-y-2 text-gray-600">
                <p className="flex items-center space-x-2">
                  <span className="font-medium">Data rozpoczęcia:</span>
                  <span>{formatDate(campaign.campaignStart)}</span>
                </p>
                <p className="flex items-center space-x-2">
                  <span className="font-medium">Data zakończenia:</span>
                  <span>{formatDate(campaign.campaignEnd)}</span>
                </p>
                {campaign.executionTimestamp && (
                  <p className="flex items-center space-x-2">
                    <span className="font-medium">Data wykonania:</span>
                    <span>{formatDate(campaign.executionTimestamp)}</span>
                  </p>
                )}
              </div>

              {/* Button moved from bottom to here */}
              <div className="mt-6 mb-4">
                <button
                  onClick={handleScheduleCampaign}
                  disabled={isLoading || campaign?.status !== 'NEW'}
                  className="w-full py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors disabled:bg-green-400"
                >
                  {isLoading ? 'Planowanie...' : 
                   campaign?.status === 'SCHEDULED' ? 'Kampania zaplanowana' :
                   campaign?.status === 'FINISHED' ? 'Kampania zakończona' :
                   campaign?.status === 'SENDING_EMAILS' ? 'Wysyłanie maili' :
                   campaign?.status === 'ERROR_SENDING_EMAILS' ? 'Błąd wysyłania' :
                   campaign?.status === 'IN_PROGRESS' ? 'Kampania w trakcie' :
                   'Zaplanuj kampanię'}
                </button>
              </div>

              {/* Statystyki */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                <div className="bg-gray-50 rounded-xl p-6 flex flex-col items-center justify-center">
                  <span className="text-sm font-medium text-gray-600 mb-2 text-center">Cele</span>
                  <div className="relative w-20 h-20 flex items-center justify-center">
                    {/* Transparent circle for targets (not visible to user) */}
                    <svg className="w-20 h-20 absolute" viewBox="0 0 100 100">
                      <circle 
                        cx="50" 
                        cy="50" 
                        r="45" 
                        fill="transparent" 
                        stroke="transparent"
                        strokeWidth="10"
                      />
                    </svg>
                    <div className="text-4xl font-bold text-gray-900 z-10">{campaign.targetsCount || 0}</div>
                  </div>
                </div>
                <div className="bg-gray-50 rounded-xl p-6 flex flex-col items-center justify-center">
                  <span className="text-sm font-medium text-gray-600 mb-2 text-center">Kliknięcia</span>
                  <div className="relative w-20 h-20 flex items-center justify-center">
                    {/* Circular progress bar for clicks */}
                    <svg className="w-20 h-20 -rotate-90 absolute" viewBox="0 0 100 100">
                      {/* Background circle */}
                      <circle 
                        cx="50" 
                        cy="50" 
                        r="45" 
                        fill="transparent" 
                        stroke="#e5e7eb"
                        strokeWidth="10"
                      />
                      {/* Progress circle */}
                      <circle 
                        cx="50" 
                        cy="50" 
                        r="45" 
                        fill="transparent" 
                        stroke="#fcd34d" 
                        strokeWidth="10"
                        strokeDasharray="283"
                        strokeDashoffset={283 - (283 * (campaign.clicksPercentage || 0) / 100)}
                        strokeLinecap="round"
                      />
                    </svg>
                    <div className="text-4xl font-bold text-gray-900 z-10">{campaign.clicksCount || 0}</div>
                  </div>
                </div>
                <div className="bg-gray-50 rounded-xl p-6 flex flex-col items-center justify-center">
                  <span className="text-sm font-medium text-gray-600 mb-2 text-center">Logowania</span>
                  <div className="relative w-20 h-20 flex items-center justify-center">
                    {/* Circular progress bar for logins */}
                    <svg className="w-20 h-20 -rotate-90 absolute" viewBox="0 0 100 100">
                      {/* Background circle */}
                      <circle 
                        cx="50" 
                        cy="50" 
                        r="45" 
                        fill="transparent" 
                        stroke="#e5e7eb"
                        strokeWidth="10"
                      />
                      {/* Progress circle */}
                      <circle 
                        cx="50" 
                        cy="50" 
                        r="45" 
                        fill="transparent" 
                        stroke="#ef4444" 
                        strokeWidth="10"
                        strokeDasharray="283"
                        strokeDashoffset={283 - (283 * (campaign.loginsPercentage || 0) / 100)}
                        strokeLinecap="round"
                      />
                    </svg>
                    <div className="text-4xl font-bold text-gray-900 z-10">{campaign.loginsCount || 0}</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Raport */}
          <div className="bg-white rounded-xl border border-gray-200 p-6 mb-8">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-900">Raport kampanii</h2>
              <button
                onClick={handleExportReport}
                disabled={isLoading}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors disabled:bg-green-400"
              >
                {isLoading ? 'Pobieranie...' : 'Pobierz raport XLSX'}
              </button>
            </div>
            <ReportTable 
              data={getReportData()} 
              onDeleteTarget={handleDeleteTarget}
              showDeleteButton={campaign?.status === 'NEW'}
            />
          </div>

          {/* Zarządzanie celami */}
          <div className="bg-gray-50 rounded-xl p-6 border border-gray-200 mb-8">
            <h3 className="text-xl font-semibold text-gray-900 mb-6">Zarządzanie celami</h3>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Dodawanie pojedynczego celu */}
              <div className="bg-white rounded-lg p-6 shadow-sm">
                <h4 className="font-medium text-gray-900 mb-4">Dodaj cel</h4>
                <form onSubmit={handleAddTarget} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Imię
                    </label>
                    <input
                      type="text"
                      value={newTargetName}
                      onChange={(e) => setNewTargetName(e.target.value)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Adres email
                    </label>
                    <input
                      type="email"
                      value={newTarget}
                      onChange={(e) => setNewTarget(e.target.value)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors disabled:bg-blue-400"
                  >
                    Dodaj
                  </button>
                </form>
              </div>

              {/* Importowanie celów z CSV */}
              <div className="bg-white rounded-lg p-6 shadow-sm">
                <h4 className="font-medium text-gray-900 mb-4">Importuj cele z CSV</h4>
                <div className="space-y-4">
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleTargetFileChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                  />
                  <button
                    onClick={handleUploadTargets}
                    disabled={isLoading || !targetFile}
                    className="w-full py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors disabled:bg-indigo-400"
                  >
                    Importuj
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Komunikaty */}
          {message && (
            <div className={`p-4 rounded-lg ${
              message.includes('pomyślnie') 
                ? 'bg-green-50 border-l-4 border-green-400 text-green-700' 
                : 'bg-red-50 border-l-4 border-red-400 text-red-700'
            }`}>
              {message}
            </div>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default CampaignDetail; 